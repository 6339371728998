import styled from 'styled-components'
import { md, lg, xl, sh } from 'config/variables'

export const LocaranimationbannerStyles = styled.div`
  padding-top: 180px;
  padding-bottom:43px;
  ${xl(`   
      padding-bottom:40px;
  `)}
  ${lg(`
      padding-top: 120px;
      padding-bottom:40px;
  `)}
  ${md(`
      padding-top: 80px;
      padding-bottom:30px;
  `)}
  .container {
    display: flex;
    justify-content: space-between;
    ${md(`
          flex-direction: column;
      `)}
    .content-part {
      max-width: 455px;
      width: 100%;
      padding-top: 105px;
      ${xl(`
           max-width: 438px;
           padding-top: 86px;
           padding-right:42px;
      `)}
       ${lg(`
           max-width: 330px;
           padding-top: 30px;
           padding-right:24px;
           padding-top:30px;
      `)}
       ${md(`
           max-width: 100%;
           padding-top: 0;
           padding-right:0px;
           padding-bottom:20px;
      `)}     
      ${sh(`
           padding-right:42px;
      `)}      
    }
    .img-block {
      max-width: 700px;
      width: 100%; 
      display:flex;
      justify-content:flex-end;
      ${xl(`
          width: 460px; 
      `)}
      ${md(`
         justify-content:center;
      `)}
  
    }
  }

  @keyframes rotation{
from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(360deg);
  }
}
@keyframes float {
0% { 
  transform: translatey(0px);
}
50% { 
  transform: translatey(-10px) ;
}
100% { 
  transform: translatey(0px);
}
}
@keyframes floatx {
0% { 
  transform: translatex(0px);
}
50% { 
  transform: translatex(-15px) scale(0.9);
}
100% { 
  transform: translatex(0px);
}
}
`
