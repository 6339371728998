import React from 'react'
import { TimeanalyticssecStyles } from './Timeanalyticssec.styles'
import Image from 'components/ImgOptimized'
import Videoplayer from 'components/Videoplayer/Videoplayer'

function Timeanalyticssec(props) {
  const { TimeanalyticsProps } = props.timeanalyticsData
  return (
    <TimeanalyticssecStyles className="timeAnalyticsSec">
      <div className="container">
        <div className="TimeanalyticsHead">
          <h2>{TimeanalyticsProps.Title}</h2>
          <p>{TimeanalyticsProps.Para}</p>
        </div>
        {props.timeAnalyticsImg ? (
          <figure>
            <Image
              fluid={props.timeAnalyticsImg}
              lazyload={{
                enabled: true,
                once: true,
                offset: 1000,
                heigth: 540,
              }}
            />
          </figure>
        ) : (
          <div className="video-section">
            {TimeanalyticsProps.videoURL ? (
              <Videoplayer videoURL={TimeanalyticsProps.videoURL} />
            ) : (
              <video autoPlay muted loop playsInline>
                <source
                  src={TimeanalyticsProps.animationvideoURL}
                  type="video/mp4"
                />
              </video>
            )}
          </div>
        )}

        {TimeanalyticsProps.TimeanalyticsList ? (
          <div className="analyticsListRow">
            {TimeanalyticsProps.TimeanalyticsList.map(
              (timeanalyticsDetails, i) => {
                return (
                  <div key={i} className="analyticsListCol">
                    <div className="icon">
                      <img src={timeanalyticsDetails.IconUrl} />
                    </div>
                    <h3>{timeanalyticsDetails.ListTitle}</h3>
                    <p>{timeanalyticsDetails.ListDetails}</p>
                  </div>
                )
              }
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </TimeanalyticssecStyles>
  )
}

export default Timeanalyticssec
