import React from 'react'

import { CaseintrofullvideosecStyles } from './Caseintrofullvideosec.styles'
import Videoplayer from 'components/Videoplayer/Videoplayer'

function Caseintrofullvideosec(props) {
  const {
    CaseintrofullvideoData: { CaseintrofullvideoProps },
  } = props
  return (
    <CaseintrofullvideosecStyles>
      <div className="container">
        <h2>{CaseintrofullvideoProps.maintile}</h2>
        <p>{CaseintrofullvideoProps.mainpara}</p>
      </div>
      <div className="video-section">
        {CaseintrofullvideoProps.videoURL ? (
          <Videoplayer videoURL={CaseintrofullvideoProps.videoURL} />
        ) : (
          <video autoPlay muted loop playsInline>
            <source
              src={CaseintrofullvideoProps.animationvideoURL}
              type="video/mp4"
            />
          </video>
        )}
      </div>
      <div className="container">
        {CaseintrofullvideoProps.subheading ||
        CaseintrofullvideoProps.paraLeft ||
        CaseintrofullvideoProps.paraRight ? (
          <div className="sub-content">
            <h3>{CaseintrofullvideoProps.subheading}</h3>
            <div className="row">
              <div className="col">
                <p>{CaseintrofullvideoProps.paraLeft}</p>
              </div>
              <div className="col">
                <p>{CaseintrofullvideoProps.paraRight}</p>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </CaseintrofullvideosecStyles>
  )
}

export default Caseintrofullvideosec
