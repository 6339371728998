import styled from 'styled-components'
import { primaryColor, md, lg, xl, white } from 'config/variables'

export const MobileappdrivercontrolStyles = styled.div`
.mobileAppsheading {
    h4 {
      font-size: 15px;
      font-weight: 600;
      margin: 0px;
      letter-spacing: -0.16px;
      line-height: 21px;
      text-transform: uppercase;
      padding-right:10px;
      background:${white};
      display: inline-block;
      position: relative;
      z-index:10;
    }
    ${md(`
      display:none;
  `)} 
  }
  .mobileAppsContent {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    ${lg(`
      padding-bottom: 30px;
  `)}
    ${md(`
        flex-direction: column; 
        padding-bottom: 40px;
    `)} 
    .mobileAppsContentLeft {
      flex: 0 1 515px;
      padding-right: 40px;
      padding-left: 84px;
      ${xl(`
          padding-right: 25px;
          padding-left: 82px;
      `)}
       ${lg(`
          padding-right: 15px;
          padding-left: 73px;
      `)}
        ${md(`
            flex: 1 1 auto;
            padding:0px;
        `)} 
      .mobileAppsTitle {
        position: relative;
        padding: 40px 0;
        ${md(`
          padding: 20px 0;
        `)} 
        &:after {
          content: '';
          position: absolute;
          top: -12px;
          left: 0px;
          width: 100%;
          height: 3px;
          background: ${primaryColor};
          ${md(`
            top:0px;
            width:200px;
        `)} 
        }
        &::before {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          max-width: 160px;
          height: 3px;
          background: ${primaryColor};
          ${md(`
            width:120px;
        `)} 
        }
        h2 {
          margin-bottom: 0px;
        }
      }
    }
    .mobileAppsContentRight {
      flex: 0 1 625px;
      padding-left: 40px;
      ${xl(`
          padding-left: 25px;
      `)}
      ${lg(`
          padding-left: 15px;
      `)}
      ${md(`
          padding-left: 0;
          flex: 1 1 auto;
      `)}
      .mobileAppsDetails {
        position: relative;
        padding: 40px 0 0;
        ${md(`
            padding: 26px 0 0;
        `)} 
        &:after {
          content: '';
          position: absolute;
          top: -12px;
          left: 0px;
          width: 100%;
          height: 3px;
          background: ${primaryColor};
           ${md(`
            display:none;
        `)} 
        }
      }
    }
  }
  .caseScreenList {
    ${md(`
       overflow:auto;
    `)} 
  }
  .case-images {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin:0 -15px;
        ${lg(`
            margin:0 -43.5px;
          `)}
         ${md(`
              width:765px;
            `)} 
        .img-blk {
          max-width: 309px;
          width: 100%;
          margin-bottom:50px;
          padding: 0 15px;
          ${xl(`
            max-width: 257px;
            margin-bottom:40px;
          `)}
          ${lg(`
            max-width: 33.33%;
            padding:0 43.5px;
            margin-bottom:15px;
          `)}
          .appScreenThumb {
            padding-bottom:20px;
            ${lg(`
              padding-bottom:15px;
            `)}
          }
          p {
            font-size:20px;
            line-height:30px;
            letter-spacing:-0.14px;
            ${xl(`
             font-size:16px;
            line-height:24px;
            `)}
            ${lg(`
             font-size:13px;
            line-height:18px;
            `)}
          }
        }
      }
      .secDiagramImgRow {
        figure {
          margin:0px;
        }
      }
`
