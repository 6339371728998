import React from 'react'
import { CasecloudbasedsecStyles } from './Casecloudbasedsec.styles'
import Image from 'components/ImgOptimized'
function AnimationSecRow(props) {
  const [isVisible, setVisible] = React.useState(false)
  const secRef = React.useRef()
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting))
    })
    observer.observe(secRef.current)
  }, [])

  return (
    <div
      ref={secRef}
      className={`secRow ${isVisible ? 'is-visible' : 'is-hidden'}`}
    >
      {props.itemsImg.map((item, i) => {
        return (
          <div key={i} className="secImgCol">
            <Image
              fluid={item}
              lazyload={{
                enabled: true,
                once: true,
                offset: 1000,
                heigth: 411,
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

function Casecloudbasedsec(props) {
  const {
    CasecloudbasedsecData: { CasecloudbasedsecProps },
  } = props

  return (
    <CasecloudbasedsecStyles className="caseCloudBasedSec">
      <div className="container">
        <div className="casecloudbasedInner">
          <AnimationSecRow itemsImg={props.secTopImgRow} />
          <div className="cloudbasedSetailsRow">
            <div className="rowContentLeft">
              <h2>{CasecloudbasedsecProps.title}</h2>
            </div>
            {props.rightDetailsImg && (
              <div className="rowImgRightCol">
                <Image
                  fluid={props.rightDetailsImg}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 411,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </CasecloudbasedsecStyles>
  )
}

export default Casecloudbasedsec
