import React from 'react'

import { LocaranimationbannerStyles } from './Locaranimationbanner.styles'

function Locaranimationbanner(props) {
  const {
    bannerdata: { locarBannerProps },
  } = props
  return (
    <LocaranimationbannerStyles className="caseAnimationBanner">
      <div className="container">
        <div className="content-part">
          <div className="banner-part">
            <h1>{locarBannerProps.heading}</h1>
            <p>{locarBannerProps.bannerPara}</p>
          </div>
        </div>
        <div className="img-block">
          <div className="bannerImage">
            {locarBannerProps.locarBannerImage.map((imageData, i) => {
              return (
                <img
                  key={i}
                  className={imageData.className}
                  src={imageData.imgUrl}
                  alt="banner"
                />
              )
            })}
          </div>
        </div>
      </div>
    </LocaranimationbannerStyles>
  )
}

export default Locaranimationbanner
