import styled from 'styled-components'
import { md, xl, lg } from 'config/variables'

export const TimeanalyticssecStyles = styled.div`
  margin-bottom: 100px;
    ${xl(`
       margin-bottom: 90px;
    `)}
    ${lg(`
       margin-bottom: 60px; 
    `)}
    ${md(`
       margin-bottom: 40px; 
    `)} 
  
  .video-section {
    margin: 74px 0 42px;
    ${lg(`
        margin: 50px 0 40px;
    `)}
    ${md(`
        margin: 30px 0 20px;
    `)}
    video {
      display:block;
      width:100%;
    }
  }
  .analyticsListRow {
    display: flex;
    margin: 0 -50px;
    justify-content: space-between;
    flex-wrap: wrap;
    ${lg(`
      flex-direction: column;
    `)}
    .analyticsListCol {
      padding: 0 50px;
      flex: 0 1 33.33%;
      ${lg(`
        flex: 1 1 auto;
        margin-bottom:60px;
      `)}
      ${md(`
        margin-bottom:40px;
      `)}
     &:last-child {
        ${lg(`
        margin-bottom:0px;
      `)}
      }
      .icon {
        padding-bottom: 25px;
      }
      h3 {
        margin-bottom: 10px;
      }
      p {
        font-size: 18px;
        line-height: 28px;
        margin: 0px;
      }
    }
  }
`
