import styled from 'styled-components'
import { xl, lg, md } from 'config/variables'

export const TmscaseresultsecStyles = styled.div`
  padding: 100px 0;
  margin-bottom: 120px;
  background-repeat: no-repeat;
  background-size: cover;
   ${xl(`
      margin-bottom: 90px;
      padding: 80px 0;
  `)} ${lg(`
      margin-bottom: 60px; 
      padding: 60px 0;
  `)} ${md(`
      margin-bottom: 40px; 
      padding: 40px 0;
  `)};
  .resultsecInner {
    max-width:940px;
    margin:0 auto; 
    padding:60px;
    border-radius: 47px;
    box-shadow: -15px 18px 35px -2px #00000030; 
    background-color: rgba(40, 44, 55, 0.81);
    ${lg(`
      padding:40px;
    `)} ${md(`
      padding:20px;
    `)};
    .secHead {
      text-align:center;
      h2 {
        color:#fff;
        position:relative;
        padding-bottom:41px;
        margin-bottom:36px;
        ${lg(`
          padding-bottom:30px;
          margin-bottom:30px;
        `)} ${md(`
        padding-bottom:20px;
          margin-bottom:20px;
        `)};
        &:after {
          content:"";
          position:absolute;
          width:100%;
          bottom:0;
          left:0px;
          right:0px;
          margin:0 auto;
          max-width:194px;
          height:3px;
          background:#fff;
          ${lg(`
            max-width:160px;
          `)} ${md(`
          max-width:130px;
          `)};
        }
      }
    }
    p {
      color:#fff;
    }
  }
`
