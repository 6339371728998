import React from 'react'

import { TmscaseresultsecStyles } from './Tmscaseresultsec.styles'

function Tmscaseresultsec(props) {
  const {
    tmsCaseResultData: { tmsCaseResultProps },
  } = props
  return (
    <TmscaseresultsecStyles
      className="tmsCaseResultSec"
      style={{ backgroundImage: `url(${tmsCaseResultProps.bgImg})` }}
    >
      <div className="container">
        <div className="resultsecInner">
          <div className="secHead">
            <h2>{tmsCaseResultProps.heading}</h2>
          </div>
          <p>{tmsCaseResultProps.para}</p>
        </div>
      </div>
    </TmscaseresultsecStyles>
  )
}

export default Tmscaseresultsec
