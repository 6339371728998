import React from 'react'
import { MobileappdrivercontrolStyles } from './Mobileappdrivercontrol.styles'
import Casequotesec from 'components/Casequotesec/Casequotesec'
import Image from 'components/ImgOptimized'

function Mobileappdrivercontrol(props) {
  const { MobileappdrivercontrolProps } = props.Mobileappdrivercontroldata
  const caseQuoteProp = MobileappdrivercontrolProps.caseQuoteProps

  return (
    <MobileappdrivercontrolStyles className="MobileappdrivercontrolSec">
      <div className="container">
        <div className="mobileAppsheading">
          <h4>{MobileappdrivercontrolProps.Title}</h4>
        </div>
        <div className="mobileAppsContent">
          <div className="mobileAppsContentLeft">
            <div className="mobileAppsTitle">
              <h2>{MobileappdrivercontrolProps.subTitle}</h2>
            </div>
          </div>
          <div className="mobileAppsContentRight">
            <div className="mobileAppsDetails">
              <p>{MobileappdrivercontrolProps.para}</p>
            </div>
          </div>
        </div>
        {MobileappdrivercontrolProps.mobileAppScreenDetails || props.items ? (
          <div className="caseScreenList">
            <div className="case-images">
              {MobileappdrivercontrolProps.mobileAppScreenDetails.map(
                (screenListDetails, i) => {
                  return (
                    <div key={i} className="img-blk">
                      {props.items && (
                        <div className="appScreenThumb">
                          <Image
                            fluid={props.items[i]}
                            lazyload={{
                              enabled: true,
                              once: true,
                              offset: 1000,
                              heigth: 593,
                            }}
                          />
                        </div>
                      )}
                      <p> {screenListDetails.screenDetails} </p>
                    </div>
                  )
                }
              )}
            </div>
          </div>
        ) : (
          ' '
        )}
        {props.diagramImg ? (
          <div className="secDiagramImgRow">
            <figure>
              <Image
                fluid={props.diagramImg}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 694,
                }}
              />
              {MobileappdrivercontrolProps.diagramImgCaption ? (
                <div className="img-caption">
                  {MobileappdrivercontrolProps.diagramImgCaption}
                </div>
              ) : (
                ' '
              )}
            </figure>
          </div>
        ) : (
          ' '
        )}
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </MobileappdrivercontrolStyles>
  )
}

export default Mobileappdrivercontrol
