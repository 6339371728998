import React from 'react'
import { images } from 'config/images'

export const MissionRabiesProps = {
  locarBannerProps: {
    heading: 'Mission Rabies',
    bannerPara: (
      <>
        A WHO backed NGO uses mHealth and precision analytics to vaccinate over
        one million dogs
      </>
    ),
    BannerAlt: 'Roundr Case Study',
    locarBannerImage: [
      {
        className: 'introMobileScreen',
        imgUrl: images.mrIntroScreen,
      },

      {
        className: 'msdImg',
        imgUrl: images.mrIntroMMsdImg,
      },
      {
        className: 'cdcImg',
        imgUrl: images.mrIntroCdcImg,
      },
      {
        className: 'pathTrackerImg',
        imgUrl: images.mrIntropathtracker,
      },
    ],
  },
  CaseintrofullvideoProps: {
    maintile: 'Introduction',
    mainpara: (
      <>
        Mission Rabies is a charity driven by a one health approach to eradicate
        rabies from the world by 2030. More than 60,000 people die of the
        disease every year,{' '}
        <strong>
          {' '}
          by vaccinating just 70% of dogs, we can eliminate the deadly disease.
        </strong>{' '}
        Therefore, Mission Rabies uses an end-to-end technology stack to
        organize mass dog vaccination programs to control the disease.
      </>
    ),
    animationvideoURL: images.mrIntroVideo,
    subheading:
      'Upscaling vaccination programs by moving to an end-to-end technology stack',
    paraLeft:
      'Mission Rabies spreads across different geographies with remote teams carrying out various vaccination programs. The absence of a central project management system became a massive hurdle in upscaling existing programs and introducing new ones. ',
    paraRight:
      'Therefore, a central managing system that records data of vaccinated dogs, areas covered, success graphs, etc. was crucial for data-driven decision making and establishing a sustainable model.',
  },
  MobileappdrivercontrolProps: {
    Title: 'Engagement',
    subTitle: <>Developing a scalable mHealth solution</>,
    para: (
      <>
        We started our engagement with mission rabies by understanding use cases
        representative of their needs. Finalizing use cases with the agile
        development process took us from whiteboard to a prototype in no time.
        The prototype included functionalities like project management, data
        collection in low bandwidth areas, path tracing, reporting etc.
      </>
    ),
  },
  TimeanalyticsProps: {
    Title: 'Result',
    Para: (
      <>
        Mission Rabies became a rare example of a mHealth system with a mobile
        app comprising a robust back-office portal and a scalable backend. The
        app successfully made progress across different geographies and helped
        the organization excel in three critical areas of operations, i.e, data
        collection, remote project management, and program monitoring. <br />
        <br />
        The app helped Mission Rabies curate reports coming from the data
        reported in the app from the ground. The reports helped them raise funds
        for ongoing and future campaigns from organizations like Dogs Trust
        Worldwide and MSD Animal Health.
        <br />
        <br />
        Since the launch, the app has recorded{' '}
        <strong>
          vaccination of over 1 million dogs, awareness programs for 3 million
          children & adults, and field surveys in 16 countries.
        </strong>{' '}
        Looking at the success of Mission Rabies, multiple charitable
        organizations and institutes started replicating its model for vaccine
        programs for different species.{' '}
        <strong>
          CDC (Center for disease control and prevention) itself adopted the
          model for human vaccinations in the US.
        </strong>
      </>
    ),
    animationvideoURL: images.mrResultVideo,
  },
  CasePowerToolsProps: {
    mainTitle: 'Powering mission rabies with robust offline solution',
    CasePowerToolsDetails: [
      {
        para: (
          <>
            Mission rabies requires its remote team to travel geographies which
            are not pro internet and devoid of running electricity. Hence, the
            app must empower users with active functionalities, with or without
            the internet, and with minimum battery usage. Also, project teams
            work with vast amounts of data when on the field. Hence having
            offline-online synchronization with data integrity and strong
            security was paramount. <br />
            <br />
            As a tech solution, we decided to make the frontend of the app
            completely offline. <strong>By leveraging AWS App sync,</strong> we
            added automatic data synchronization functionality in the app.
            Whenever the device connects to the internet, they receive all the
            data from the server, and data collected via forms will
            automatically update itself. <br />
            <br />
            To safeguard battery usage, we implemented some battery optimization
            features. We batched network calls and eliminated unwanted wakeup
            calls. For android applications, we used GCMNetworkManager API and
            GPU rendering to reduce battery consumption.
          </>
        ),
      },
    ],
  },
  introducrtionContent: {
    maintile:
      'Remote project management with robust back office support & Data rich dashboard',
    mainpara: (
      <>
        One of the critical challenges Mission Rabies wanted to solve was the
        distribution and monitoring of ground tasks. Volunteers at Mission
        Rabies vaccinate 20k+ dogs and educates over 90k+ children every month.
        The data collected from these activities needed processing on a daily
        basis, increasing the need for analytics and smart reports. Also,
        Scaling with the age-old practice of project management made it nearly
        impossible for the organization. <br />
        <br />
        Hence,{' '}
        <strong>
          we built a back office portal to assign tasks to their field teams
          remotely.
        </strong>{' '}
        Using the admin panel, a location on the map is marked, based on which
        teams are assigned work. We also enabled{' '}
        <strong> built-in chat support using a socket-based connection,</strong>{' '}
        which facilitated close communication between the manager and the team.
        <br />
        <br />
        We improved the{' '}
        <strong>
          data visualization process by designing custom dashboards on PowerBI.
        </strong>{' '}
        A business analytics system that allows users to create custom reports
        based on operation data. It was a significant upgrade from the existing
        excel sheets and enabled quick data analysis, team direction, and
        project management.
      </>
    ),
  },
  CasecloudbasedsecProps: {
    title:
      'We built a back office portal to assign task to field teams remotely',
  },
  locationVisualizationProps: {
    trackheading:
      'Location visualization and path tracking with KML files for Geospatial analysis',
    ReliabilityinstancesDetails: [
      {
        animationvideoURL: images.mrMapAnimationScreen,
        trackbottomPara: (
          <>
            Field visits were often in locations unknown to volunteers. Hence,
            to ensure accuracy during field visits, we made use of KML files,
            which uses 3d geographic coordinates exported from google maps to
            show regions and locations. We added functionality on our backend
            side, which allows <strong>KML files</strong> to be imported from
            our original database and exported to the mobile application. By
            using KML files, teams can target exact locations assigned to them.{' '}
            <br />
            <br />
            To make sure that no area is missed or repeated, we integrated a
            path tracking system in our mobile app. Whenever a volunteer tracks
            a specific region, the data gets stored in our app, which
            automatically syncs with the server. By using this data entry,{' '}
            <strong>
              GPS data gets collected, allowing Mission Rabies to conduct a
              geospatial analysis of fieldwork.
            </strong>
          </>
        ),
      },
    ],
  },

  casecontentfullwProps: {
    heading: 'Data collection, storage and analysis made easy with IBCM & AWS',
    para: (
      <>
        Data is the asset that drives operations at Mission Rabies. The team is
        heavily dependent on the information for vaccination programs and
        fundraisers. Hence, we decided to integrate easy data entry points
        structured seamlessly into the app.
        <br />
        <br />
        During field visits, users are supposed to add data to the app.{' '}
        <strong>
          Hence, we made use of customizable dynamic mobile forms in compliance
          with IBCM - Integrated Bite case management module.
        </strong>{' '}
        These forms change the entire questionnaire smartly based on users' data
        input and remove any input field that is not required. It made large
        scale data collection extremely easy on mobile phones.
      </>
    ),
    bottomPara: (
      <>
        We knew that utilizing dynamic forms could be a disaster if the database
        has issues with its design. Dynamic forms can slow down the entire
        system by at least four times.{' '}
        <strong>
          Hence we developed an AWS based scalable cloud infrastructure to
          ensure proper working of the application.
        </strong>
        <br />
        <br />
        Since the team used to process a lot of data on a day-to-day basis, we
        needed scalable backend support & database to support data processing
        and storage. Therefore, we used C#, a popular choice for big data type
        applications, for the backend and SQL database to store the structured
        data. We also implemented hash indexes to make queries faster by
        utilizing multi-az database. To host the database, we leveraged{' '}
        <strong>Amazon RDS (Relational Database Service),</strong> which takes
        care of crucial tasks like hardware provisioning, database setup,
        patching, and backups.
      </>
    ),
  },
  dataSecurityProps: {
    trackheading: 'Advanced Data Handling and Data Security',
    ReliabilityinstancesDetails: [
      {
        trackbottomPara: (
          <>
            Data is a huge part of Mission Rabies; it defines the work, success,
            and the societal change the organization is causing. Hence, the app
            needed to secure data.
            <br />
            <br />
            We added double-layer security{' '}
            <strong>
              with database file encryption and auto removal functionality
            </strong>{' '}
            in the app to ensure data security at the user level. Database file
            encryption only decrypts the file which is used by the app or the
            user. If a user tries to access a file from the file system, he
            won't be able to use it as it is encrypted once the app thread is
            closed. On the other hand, auto removal functionality deletes all
            the offline data after synchronizing it with the server.
          </>
        ),
      },
    ],
    bottomPara: (
      <>
        As a part of data collection activity, data entered on smartphones was
        stored offline locally in the handset before uploading it on an{' '}
        <strong>SSL secured cloud-based server.</strong> To secure the
        unauthorized access of API data, we added a{' '}
        <strong>token-based authentication </strong> to ensure data security is
        maintained at the highest levels when in transit.
        <br />
        <br />
        To reduce the risk of data threats and security breaches, we added{' '}
        <strong>AWS firewall</strong> to the web app and{' '}
        <strong>throttling limits at the API level.</strong> Firewall protected
        the web app against security breaches, and API throttling added a border
        to the API calls and rejected the unwanted requests.
      </>
    ),
  },
  tmsCaseResultProps: {
    bgImg: images.mrMicrochipsBg,
    heading: 'Identifying infected and injected animals with RFID microchips ',
    para: (
      <>
        Dogs have to be vaccinated multiple times during a lifetime to ensure
        prevention from rabies. Hence, keeping a record of vaccinated dogs is a
        crucial data point for mission rabies. <br />
        <br />
        Therefore, we integrated RFID (Radio-frequency identification tags),
        which goes in the form of a microchip in dogs' bodies. These are
        non-allergic, non-toxic microchips that don't hurt the implanted
        animals. During field visits, volunteers need to access dogs'
        vaccination history to make new vaccine decisions. So, we integrated a
        connection in the app for RFID scanners. Using BLE (Bluetooth Low
        Energy), a wireless network technology which establishes a connection
        between the app and RFID scanner with a very low consumption power.
        Using the c onnection, volunteers or workers get full access to the
        dog's vaccination history in the app.
      </>
    ),
  },
  amazonSNSProps: {
    trackheading:
      'Custom and push notifications made possible with  Amazon SNS',
    ReliabilityinstancesDetails: [
      {
        trackbottomPara: (
          <>
            Assigning tasks to remote teams was a crucial part of team
            management. To make the activity swift, we integrated AWS SNS
            (Simple Notification Service) to enable custom messaging. Using SNS,
            managers were able to communicate custom messages and push
            notifications to teams to assign tasks, report emergencies, etc.
          </>
        ),
      },
    ],
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'Roundr',
        para: (
          <>
            An end-to-end technology stack to boost South Africa's Real Estate
            business
          </>
        ),
        className: 'light-peach-five',
        link: 'case-studies/roundr/',
      },
      {
        title: 'Accent Connect',
        para: <>Sales enablement tool to take your sales to the next level</>,
        className: 'soft-pink-two',
        link: 'case-studies/accent-connect/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
