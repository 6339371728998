import styled from 'styled-components'
import { xl, lg, md, white } from 'config/variables'

export const CasecloudbasedsecStyles = styled.div`
  background-image: linear-gradient(to bottom, #25497b, #0e1c2f 122%);
  padding: 1px 0 30px;
  margin-bottom: 120px; 
  margin-top:72px; 
  ${xl(`   
    margin-bottom: 100px; 
    margin-top:60px; 
  `)}
  ${lg(`
    margin-bottom: 60px; 
    margin-top:45px; 
  `)}
  ${md(`
    margin-bottom: 40px;  
    margin-top:65px; 
  `)} 
  .casecloudbasedInner {
    position:relative;    
    .secRow {
      display:flex; 
      margin:-73px -10px 0;  
      perspective: 400px;
      ${xl(`
        margin:-60px -10px 0;  
      `)}
      ${lg(`
        margin:-45px -10px 0;  
      `)}
      ${md(`
        margin:-25px -10px 0;  
      `)}
      .secImgCol {
        max-width:256px;
        width: 100%;
        padding:0 10px;
        opacity: 0;
        transform: rotateY(-90deg);
        transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
        ${xl(`   
          max-width:200px;
        `)}
         ${lg(`
          max-width:150px; 
        `)}
      }
      &.is-visible {
        .secImgCol { 
          opacity: 1;
          transform: none;
          transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
          &:first-child {
          transition-delay: 0.4s;
          }
          &:nth-child(2) {
            transition-delay: 0.6s;
          }
          &:last-child {
            transition-delay: 0.8s;
          }
        }
      }
    }
  .cloudbasedSetailsRow {
      display:flex;
      justify-content:space-between;
      align-items:center;  
      ${md(`
        flex-direction: column;
        padding-top:40px;
      `)}
      .rowContentLeft {
        max-width:411px;
        flex:0 0 411px; 
        padding-right:20px;
        ${lg(`
            max-width:350px;
            flex:0 0 350px; 
        `)}
        ${md(`
          max-width:100%;
          flex:1 1 auto; 
          padding-right:0; 
        `)}
        h2 {
          font-size:40px;
          color:${white};
          letter-spacing: -0.43px;  
          line-height: 1.2; 
          ${lg(`
            font-size:32px;
          `)}
        }
      }
      .rowImgRightCol {
        max-width:654px;
        flex:1 1 auto; 
        width: 100%;
      }
  }
}
`
