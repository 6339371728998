import styled from 'styled-components'
import { bodyFont, lg, md, xl } from 'config/variables'

export const CaseintrofullvideosecStyles = styled.div`
 margin-bottom: 73px;
  ${lg(`
    margin-bottom: 60px;
  `)}
  ${md(`
    margin-bottom: 40px;
  `)}
  p {
    margin-bottom: 43px;
  }
  .video-section {
    margin: 88px 0 124px;
    ${xl(`
       margin: 60px 0 100px;
    `)}
    ${lg(`
       margin: 50px 0 60px;
    `)}
    ${md(`
       margin: 40px 0 40px;
    `)} 
    video {
      display:block;
      width:100%;
    }
  }
  .sub-content {
    h3 {
      font-family: ${bodyFont};
      font-weight: 500;
      margin-bottom: 23px;
    }
    .row {
      display: flex;
      justify-content: space-between;
      ${md(`
        flex-direction: column;
      `)}
      .col {
        width: 47.5%;
        ${md(`
          width: 100%;
        `)} 
        p {
          margin-bottom:0px; 
        }
      }
    }
  }
`
