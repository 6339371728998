import React from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { MissionRabiesProps } from 'components/Props/mission-rabies'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { xl, lg, md, sm } from 'config/variables'
import Locaranimationbanner from 'components/Locaranimationbanner/Locaranimationbanner'
import Caseintrofullvideosec from 'components/Caseintrofullvideosec/Caseintrofullvideosec'
import Mobileappdrivercontrol from 'components/Mobileappdrivercontrol/Mobileappdrivercontrol'
import Timeanalyticssec from 'components/Timeanalyticssec/Timeanalyticssec'
import CasePowerTools from 'components/CasePowerTools/CasePowerTools'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Casecloudbasedsec from 'components/Casecloudbasedsec/Casecloudbasedsec'
import Reliabilityinstancessec from 'components/Reliabilityinstancessec/Reliabilityinstancessec'
import Casecontentfullwimg from 'components/Casecontentfullwimg/Casecontentfullwimg'
import Tmscaseresultsec from 'components/Tmscaseresultsec/Tmscaseresultsec'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
export const MissionRabiesPage = styled.div`
  .caseAnimationBanner {
    padding-bottom: 120px; 
    ${xl(`
       padding-bottom: 90px;
    `)}
    ${lg(`
       padding-bottom: 60px; 
    `)}
    ${md(`
       padding-bottom: 40px; 
    `)} 
    .img-block { 
      .bannerImage {
        width: 621px;
        height:676px;
        position: relative;
        ${xl(`
          width: 460px;
          height: 470px;
        `)}
        ${lg(`
           width: 351px;
           height: 382px;
        `)}
        ${sm(`
           width: 280px;
           height: 305px;
        `)}
        img {
          position:absolute;
          &.introMobileScreen {
            top:0;
            right:0;  
          }   
          &.msdImg { 
            top: 110px;
            right: 28px;
            animation: float 8s ease-in-out infinite;
            ${xl(` 
                max-width: 150px;
                top: 85px;
                right: 25px;
            `)}
            ${lg(`
                max-width: 100px;
                top: 70px;
                right: 28px;
            `)}
            ${sm(`
                max-width: 80px;
                top: 60px;
                right: 25px;
            `)}
          }   
          &.cdcImg { 
            top: 100px;
            left: 90px;
            border-radius: 16px; 
            animation: floatWithShadw 8s ease-in-out infinite;
            ${xl(`
                top: 70px;
                left: 60px;
                max-width: 80px;
            `)}
            ${lg(`
                top: 55px;
                left: 45px;
                max-width: 60px;
                border-radius: 10px; 
            `)}
            ${sm(`
                top: 45px;
                left: 35px;
                max-width: 50px;
                border-radius: 10px;
            `)}
          }    
          &.pathTrackerImg {
            left: 174px;
            bottom: 300px;
            max-width:140px;
	          animation: floatWithShadw 8s ease-in-out infinite;
            z-index: 1;
            ${xl(`
                max-width:110px;
                left: 126px;
                bottom: 190px;
            `)}
            ${lg(`
                max-width: 80px;
                left: 99px;
                bottom: 170px;
            `)}
            ${sm(`
                max-width: 60px;
                left: 75px;
                bottom: 135px;
            `)}
          }   
        }        
      }
    }
  } 
  .MobileappdrivercontrolSec {
    margin-bottom: 120px;
    ${xl(`
       margin-bottom: 90px;
    `)}
    ${lg(`
       margin-bottom: 60px; 
    `)}
    ${md(`
       margin-bottom: 40px; 
    `)} 
    .mobileAppsContent {
      .mobileAppsContentLeft {
        padding-left: 115px; 
          ${md(` 
          padding:0px;
          `)} 
        .mobileAppsTitle {
          &::before {
            display:none;
          }
        }
      }
    } 
  } 
  .timeAnalyticsSec {
    .TimeanalyticsHead {
      h2 {
        text-align:center;
        padding-bottom:41px;
        margin-bottom:41px;
        position: relative;
        ${lg(`
          padding-bottom:30px;
          margin-bottom:30px;
        `)}
        ${md(`
          padding-bottom:20px;
          margin-bottom: 20px; 
        `)} 
        &::after {
          content:"";
          position: absolute;
          bottom:0;
          left:0;
          right:0;
          width:100%;
          max-width:194px;
          margin:0 auto;
          height:3px;
          background:#3d3d3d;
          ${lg(`
            max-width:180px;
          `)}
          ${md(`
            max-width:150px;
          `)} 
        }
      }
      .video-section {
        margin:120px 0 0;
        ${xl(`
          margin: 90px 0 0;
        `)}
        ${lg(`
          margin: 60px 0 0; 
        `)}
        ${md(`
          margin: 40px 0 0; 
        `)} 
      }
    }
  }
  .casepower-section {
    position: relative;
    margin: 0 0 120px;
    padding-bottom:100px;
    ${xl(`
      margin:0 0 90px;
      padding-bottom:90px;
    `)}
    ${lg(`
      margin: 0 0 60px; 
      padding-bottom:60px;
    `)}
    ${md(`
      margin:0 0 40px; 
      padding-bottom:40px;
    `)} 
    &::after {
      content: '';
    background: linear-gradient(to bottom,rgba(252,253,255,0),#f7f7f7);
    position: absolute;
    width: 100%;
    height: 633px;
    bottom: 0;
    left: 0;
    z-index: -1;
    }
  }
  .caseCloudBasedSec {
    background-image: linear-gradient(to bottom, #ab745b, #90614d);
    .casecloudbasedInner {
      .cloudbasedSetailsRow {
        .rowContentLeft {
          h2 {
            font-size:50px;
            line-height:58px;
            margin:0px;
            ${xl(`
                font-size: 44px;
                line-height: 53px; 
            `)}
            ${lg(`
                font-size: 44px;
                line-height: 53px; 
            `)}
            ${md(`
                font-size: 42px;
                line-height: 50px; 
                margin-bottom:20px;
            `)}
            ${sm(`
                font-size: 36px;
                line-height: 42px; 
            `)}
          }
        }
        .rowImgRightCol {
          max-width: 718px;
        }
      }
    }
  }
  .locationVisualization {
    .reliabilityinstancessec  {
      .heading {
        h2 {
          margin-bottom: 30px;
          padding-bottom: 30px;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0px;
            width: 251px;
            height: 3px;
            background: #3d3d3d;
            ${md(`
                width: 90px;
            `)}
          }
        }
      }
    }
  }
  .tmsCaseResultSec {
    .resultsecInner {
      background:#ffffff;
      max-width:1135px;
      .secHead {
        max-width:766px;
          margin: 0 auto;
        h2 {
          color:#3d3d3d;
         
          &::after {
            background: #3d3d3d;
          }
        }
      }
      p {
        color:#3d3d3d;
      }
    }
  }
  @keyframes floatWithShadw {
    0% { 
      transform: translatey(0px) scale(1);
      filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.3)); 
    }
    50% { 
      transform:translatey(-6px) scale(1.02);
      filter:drop-shadow(0px 15px 15px rgba(0,0,0,0.3)); 
    }
    100% { 
      transform: translatey(0px) scale(1);
      filter:drop-shadow(0px 0px 10px rgba(0,0,0,0.3)); 
    }
  }       
`
const MissionRabies = props => {
  const { data } = props
  const { location } = props

  const scalabilityDiagram = props.data.scalabilityDiagram.childImageSharp.fluid
  let crayPayCusExpeImg = [props.data.crayPayCusExpeImg.childImageSharp.fluid]
  let secTopImgRow = [
    props.data.secTopImgRowOne.childImageSharp.fluid,
    props.data.secTopImgRowTwo.childImageSharp.fluid,
    props.data.secTopImgRowThree.childImageSharp.fluid,
  ]
  const rightDetailsImg = props.data.rightDetailsImg.childImageSharp.fluid
  const dataCollection = props.data.dataCollection.childImageSharp.fluid

  let dataSecurityImg = [props.data.dataSecurityImg.childImageSharp.fluid]
  let amazonSNSCard = [props.data.amazonSNSCard.childImageSharp.fluid]

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })
  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="Mission Rabies"
        description=""
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/case-studies/mission-rabies/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/case-studies/mission-rabies/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <MissionRabiesPage>
        <Locaranimationbanner bannerdata={MissionRabiesProps} />
        <Caseintrofullvideosec CaseintrofullvideoData={MissionRabiesProps} />
        <div className="lazy-load-div">
          <Mobileappdrivercontrol
            Mobileappdrivercontroldata={MissionRabiesProps}
            {...props}
            diagramImg={scalabilityDiagram}
          />
        </div>
        {showContent && (
          <>
            <Timeanalyticssec
              timeanalyticsData={MissionRabiesProps}
              {...props}
            />
            <CasePowerTools
              CasePowerToolsdata={MissionRabiesProps.CasePowerToolsProps}
              items={crayPayCusExpeImg}
            />
            <Caseintroduction
              caseintrodata={MissionRabiesProps}
              {...props}
              showFullimages={true}
            />
            <Casecloudbasedsec
              CasecloudbasedsecData={MissionRabiesProps}
              secTopImgRow={secTopImgRow}
              rightDetailsImg={rightDetailsImg}
            />
            <div className="locationVisualization">
              <Reliabilityinstancessec
                ReliabilityinstancesData={
                  MissionRabiesProps.locationVisualizationProps
                }
                {...props}
              />
            </div>
            <Casecontentfullwimg
              casecontentfullwdata={MissionRabiesProps.casecontentfullwProps}
              fluid={dataCollection}
            />
            <div className="locationVisualization">
              <Reliabilityinstancessec
                ReliabilityinstancesData={MissionRabiesProps.dataSecurityProps}
                {...props}
                fluid={dataSecurityImg}
              />
            </div>
            <Tmscaseresultsec tmsCaseResultData={MissionRabiesProps} />
            <Reliabilityinstancessec
              ReliabilityinstancesData={MissionRabiesProps.amazonSNSProps}
              {...props}
              fluid={amazonSNSCard}
            />
            <Relatedcaselist
              relatedcaselistdata={MissionRabiesProps}
              {...props}
            />
            <Casecontactcardinfo Casecontactcardinfodata={MissionRabiesProps} />
          </>
        )}
      </MissionRabiesPage>
    </Layout>
  )
}

export default MissionRabies

export const pageQuery = graphql`
  query {
    bannerImage: file(
      relativePath: { regex: "/mission-rabies-intro@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    scalabilityDiagram: file(
      relativePath: { regex: "/mr-engagement-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    managingDeashboard: file(
      relativePath: { regex: "/managing-deashboard@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    crayPayCusExpeImg: file(
      relativePath: { regex: "/mr-pathtracker-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 560) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/mr-dashboard-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1060) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    secTopImgRowOne: file(
      relativePath: { regex: "/mr-managing-seller@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 226) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    secTopImgRowTwo: file(
      relativePath: { regex: "/mr-paper-intensive@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 226) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    secTopImgRowThree: file(
      relativePath: { regex: "/mr-schedule-viewing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 226) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    rightDetailsImg: file(
      relativePath: { regex: "/mr-assign-task-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 718) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locationVisualization: file(
      relativePath: { regex: "/location-visualization-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 425) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dataCollection: file(
      relativePath: { regex: "/mr-data-collection-daigram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1008) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dataSecurityImg: file(
      relativePath: { regex: "/mr-data-security@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1008) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    amazonSNSCard: file(
      relativePath: { regex: "/mr-amazon-sns-card@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 540) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/roundr-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/accent-casestudies-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
